<template>
  <div id="feedback" v-loading="loading">
    <div class="cm-content">
      <el-table :data="data">
        <el-table-column label="用户ID" prop="user_id"></el-table-column>
        <el-table-column label="反馈截图">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.img_url.length > 0"
              type="text"
              @click="handleViewImage(scope.row.img_url)"
              >查看图片</el-button
            >
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="反馈内容">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.content.length > 10"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.content"
            >
              <div slot="reference">
                {{ scope.row.content.substr(0, 10) }}...
              </div>
            </el-popover>
            <div v-else-if="scope.row.content.length > 0">
              {{ scope.row.content }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="处理状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatComplaintState }}
          </template>
        </el-table-column>
        <el-table-column label="反馈时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-image ref="dialog"></d-image>
    </div>
  </div>
</template>

<script>
import DImage from "@/components/dialogs/DImage";

import feedback from "@/api/feedback";

export default {
  name: "feedback",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    DImage,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      feedback.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleViewImage(val) {
      this.$refs.dialog.handleShow(val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "feedback",
          query: params,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "feedback") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#feedback {
  display: flex;
  height: 100%;
}
</style>

import api from "@/api";

const feedback = {
  list(params) {
    return api.get(`/feedback`, {
      params: params,
    });
  },
};

export default feedback;
